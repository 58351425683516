import React, { useState } from "react";

export const DoNotTrackContext = React.createContext({
  doNotTrack: true,
  setDoNotTrack: menu => {},
});

export const DoNotTrackContextProvider = props => {
  const setDoNotTrack = menu => {
    setState({ ...state, doNotTrack: menu });
  };

  const initialState = {
    // Setting default value so that we do not track until the value is evaluated
    doNotTrack: true,
    setDoNotTrack: setDoNotTrack,
  };

  const [state, setState] = useState(initialState);

  return (
    <DoNotTrackContext.Provider value={state}>
      {props.children}
    </DoNotTrackContext.Provider>
  );
};
