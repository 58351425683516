import axios from "axios";
import { ENV_GATSBY_BASE_URL } from "../utils/secrets";

const apiService = axios.create({ baseURL: ENV_GATSBY_BASE_URL });

export async function subscribeToNewsletter(data: { email: string }) {
  try {
    return apiService.post("/subscribe-newsletter", data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}
export async function landLordSignup(data: {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  receiver_type: string;
}) {
  try {
    const res = await apiService.post('/invite-landlord', data);
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
