import React from "react";
import { Dialog } from "@headlessui/react";
import CloseIcon from "../images/close.svg";

interface Props {
  isVisible: boolean;
  setIsVisible: () => void;
  data: { embedUrl: string; header: string };
}

const EmbedModal: React.FC<Props> = props => {
  const { isVisible, setIsVisible, data } = props;

  return (
    <Dialog
      open={isVisible}
      onClose={setIsVisible}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="relative bg-white rounded w-full max-w-3xl m-3 lg:mx-auto">
          <h3 className="text-2xl font-normal text-center p-3 truncate pr-8">
            {data.header}
          </h3>
          <div
            className="w-5 h-5 absolute top-4 right-3 cursor-pointer"
            onClick={setIsVisible}
          >
            <img src={CloseIcon} alt="Cross" className="h-full w-full" />
          </div>
          <div className="border border-gray-200">
            <iframe src={data.embedUrl} className="w-full h-[80vh]"></iframe>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EmbedModal;
