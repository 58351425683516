import React, { useState } from "react";
import { subscribeToNewsletter } from "../services/apiService";
import logo from "../images/logo.png";
import { Link } from "gatsby";
import NavList from "./NavList";
import { TERMS_AND_POLICY_LINKS, SOCIAL_LINKS } from "../utils/constants";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormText from "./FormText";
import { toast } from "react-toastify";
import MAnchor from "./MAnchor";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("This is not a valid email")
    .required("This Field is Required"),
});

interface FooterProps {
  setShowRequestApiDocsModal: Function;
  setShowDtcApplyModal: Function;
}

const Footer: React.FC<FooterProps> = ({
  setShowRequestApiDocsModal,
  setShowDtcApplyModal,
}) => {
  const [success, setSuccess] = useState<string>();

  const handleSubmit = async (
    formValues: { email: string },
    formAction: FormikHelpers<{ email: string }>
  ) => {
    try {
      formAction.setSubmitting(true);
      const email = formValues.email;
      await subscribeToNewsletter({ email });
      setSuccess(
        `Your subscription was successful! Kindly check your mailbox and confirm your subscription. If you don't see the email within a few minutes, check the spam/junk folder.`
      );
      formAction.resetForm();
    } catch (e) {
      setSuccess(undefined);
      toast.error("Something went wrong!!! Please try later.");
    } finally {
      formAction.setSubmitting(false);
    }
  };

  return (
    <footer className="flex flex-col max-w-6xl mx-auto bg-[url('../images/ra555fiki.png')] bg-no-repeat bg-bottom font-inter">
      <div className="mt-8 p-2.5 pb-28">
        <div className="p-2.5">
          <Link to="/">
            <img src={logo} alt="logo" height={60} width={60} />
          </Link>
        </div>
        <div className="p-2.5 flex flex-col md:flex-row space-y-5 md:space-y-0">
          <div className="w-full md:w-1/2">
            <div>
              <ul className="flex flex-row flex-wrap space-x-2 text-base font-bold">
                <li>
                  <MAnchor
                    url={SOCIAL_LINKS.facebook}
                    ariaLabel="Facebook Icon"
                  >
                    Facebook
                  </MAnchor>
                </li>
                <li>
                  <MAnchor
                    url={SOCIAL_LINKS.intagram}
                    ariaLabel="Instagram Icon"
                  >
                    Instagram
                  </MAnchor>
                </li>
                <li>
                  <MAnchor
                    url={SOCIAL_LINKS.linkedIn}
                    ariaLabel="LinkedIn Icon"
                  >
                    LinkedIn
                  </MAnchor>
                </li>
              </ul>
            </div>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                isSubmitting,
                values,
                handleSubmit,
                errors,
              }) => {
                return (
                  <>
                    <div className="mt-10 mb-2.5 font-bold">Subscribe Us</div>
                    <div className="sm:max-w-xs">
                      {!success ? (
                        <form onSubmit={handleSubmit}>
                          <FormText
                            showSubmitButton
                            isSubmitting={isSubmitting}
                            required
                            label="E-Mail Address"
                            onChange={handleChange}
                            name="email"
                            id="email"
                            value={values.email}
                            inputClassName="w-full border-b-2 border-b-gray-300 focus:outline-none focus:border-b-black bg-transparent"
                            labelClassName="!font-normal"
                            error={errors.email}
                          />
                        </form>
                      ) : (
                        <span className="text-success">
                          Your subscription was successful! Kindly check your
                          mailbox and confirm your subscription. If you don't
                          see the email within a few minutes, check the
                          spam/junk folder.
                        </span>
                      )}
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
          <div className="w-full mr-10 sm:w-1/2 md:w-1/4">
            <div>
              <h2 className="font-bold text-base mb-2.5">Contact Info</h2>
              <p>
                700 Canal Street 1st Floor
                <br /> Stamford, CT 06902 <br /> 800-848-8412
              </p>
            </div>
          </div>
          <nav className="w-full sm:w-1/2 md:w-1/5">
            <h2 className="font-bold text-base mb-2.5">Quick Links</h2>
            <NavList
              className="space-y-1"
              setShowRequestApiDocsModal={setShowRequestApiDocsModal}
              setShowDtcApplyModal={setShowDtcApplyModal}
            />
          </nav>
        </div>
      </div>
      <div className="flex flex-col-reverse p-5 text-sm md:flex-row md:justify-between">
        <p>&copy;Matrix, 2019-2022</p>
        <div className="flex flex-col md:flex-row md:space-x-5">
          <MAnchor
            url={TERMS_AND_POLICY_LINKS.termsAndConditions}
            className="hover:text-primary"
            ariaLabel="Terms and Conditions"
          >
            Terms of Service
          </MAnchor>
          <MAnchor
            url={TERMS_AND_POLICY_LINKS.privacyPolicy}
            ariaLabel="Privacy Policy"
            className="hover:text-primary"
          >
            Privacy Policy
          </MAnchor>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
