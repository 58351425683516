import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import { DoNotTrackContext } from "../contexts/do-not-track.context";
import GtagScript from "./gtag-script/GtagScript";
import withAppContext from "../contexts/app.context";
import DtcApplyModal from "./DtcApplyModal";
import EmbedModal from "./EmbedModal";
import { HUBSPOT_FORMS } from "../utils/constants";

interface PageWrapperProps {
  path?: string;
  children?: JSX.Element;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ path, children }) => {
  const { setDoNotTrack } = useContext(DoNotTrackContext);
  const [
    showRequestApiDocsModal,
    setShowRequestApiDocsModal,
  ] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = React.useState(false);

  useEffect(() => {
    // Setting Do Not Track Context
    // @ts-ignore
    const dnt = navigator.doNotTrack || window.doNotTrack;
    setDoNotTrack(dnt === "1" || dnt === "yes");
  }, [setDoNotTrack]);

  return (
    <div className="font-primary">
      <Header
        path={path}
        setShowRequestApiDocsModal={setShowRequestApiDocsModal}
        setShowDtcApplyModal={setDialogVisible}
      />
      <div className="px-2.5">{children}</div>
      <Footer
        setShowRequestApiDocsModal={setShowRequestApiDocsModal}
        setShowDtcApplyModal={setDialogVisible}
      />
      <GtagScript />
      <ToastContainer />
      {showRequestApiDocsModal && (
        <EmbedModal
          data={{
            embedUrl: HUBSPOT_FORMS.apiDocs,
            header: "Request API Documentation",
          }}
          isVisible={showRequestApiDocsModal}
          setIsVisible={() => setShowRequestApiDocsModal(false)}
        />
      )}
      <EmbedModal
        data={{
          embedUrl: HUBSPOT_FORMS.managerLearnMore,
          header: "Apply now",
        }}
        isVisible={dialogVisible}
        setIsVisible={() => setDialogVisible(false)}
      />
    </div>
  );
};

export default React.memo(withAppContext(PageWrapper));
