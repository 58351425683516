import React from "react";
import cx from "classnames";

interface Props {
  url: string;
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
}

const MAnchor: React.FC<Props> = (props: Props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={cx("flex items-center", props.className)}
      href={props.url}
      onClick={event => event.stopPropagation}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </a>
  );
};

export default React.memo(MAnchor);
