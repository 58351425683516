import React from "react";
import { DoNotTrackContextProvider } from "./do-not-track.context";

const withAppContext = Component => {
  return props => (
    <DoNotTrackContextProvider>
      <Component {...props} />
    </DoNotTrackContextProvider>
  );
};

export default withAppContext;
