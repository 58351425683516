import { Link } from "gatsby";
import React, { useState } from "react";
import { ROUTE_LINKS } from "../utils/routes";
import logo from "../images/logo.png";
import navMenuIcon from "../images/menuIcon.png";
import NavList from "./NavList";
import HeaderNavList from "./HeaderNavList";

interface HeaderProps {
  path?: string;
  setShowRequestApiDocsModal: Function;
  setShowDtcApplyModal: Function;
}

const Header: React.FC<HeaderProps> = ({
  path,
  setShowRequestApiDocsModal,
  setShowDtcApplyModal,
}) => {
  const [isNavMenuActive, setIsNavMenuActive] = useState<boolean>(false);

  return (
    <header className="relative flex justify-between w-full max-w-6xl md:mx-auto">
      <span className="flex-shrink-0 p-5 hover:cursor-pointer">
        <Link to={ROUTE_LINKS.index}>
          <img src={logo} alt="Logo" />
        </Link>
      </span>
      <div className="flex items-center justify-end w-full md:hidden">
        <span className="p-4">
          <img
            src={navMenuIcon}
            alt=""
            className="w-6 h-6 hover:cursor-pointer"
            onClick={() => setIsNavMenuActive(!isNavMenuActive)}
          />
        </span>
      </div>
      {isNavMenuActive && (
        <div className="absolute w-full bg-white top-20 md:hidden">
          <NavList
            currentRoute={path}
            navItemClassName={
              "border-b px-6 py-3 text-base border-b-borderColor"
            }
            setShowRequestApiDocsModal={val => {
              setShowRequestApiDocsModal(val);
              setIsNavMenuActive(false);
            }}
            setShowDtcApplyModal={val => {
              setShowDtcApplyModal(val);
              setIsNavMenuActive(false);
            }}
          />
        </div>
      )}
      <HeaderNavList
        currentRoute={path}
        setShowRequestApiDocsModal={setShowRequestApiDocsModal}
        setShowDtcApplyModal={setShowDtcApplyModal}
      />
    </header>
  );
};

export default React.memo(Header);
