export enum SOCIAL_LINKS {
  facebook = "https://www.facebook.com/MatrixRentalSolutions",
  intagram = "https://www.instagram.com/matrixrentalsolutions",
  linkedIn = "https://www.linkedin.com/company/matrix-rental-solutions",
}

export const HUBSPOT_FORMS = {
  renterLearnMore: "https://share.hsforms.com/17uNJmOwaSVaO3mISxyljRg55wm5",
  managerLearnMore: "https://share.hsforms.com/16RNUpZGTQL-UCD6fdpHiBA55wm5",
  apiDocs: "https://share.hsforms.com/14cz1zO1AQNeGGdT7gfsc9w55wm5",
};

export const TERMS_AND_POLICY_LINKS = {
  termsAndConditions:
    "https://app.termly.io/document/terms-and-conditions/67b9ecd5-78be-4d8f-977b-11831d61c090",
  privacyPolicy:
    "https://app.termly.io/document/privacy-policy/8d86a6a9-b7b5-4db9-ba68-5c3a6b0470c4",
};

export const offersInformation = [
  "Ability to work in a fast paced environment and grow with a dynamic team of talented humans (and machines!)",
  "Comprehensive health insurance: Health insurance plan that covers medical, dental, and vision care for employees and their eligible dependents",
  "Paid time off: Generous amount of paid time off, including vacation days, sick days, and holidays",
  "Professional development: Opportunities for employees to continue their education and professional development through training programs, conferences, and other resources",
  "Flexible schedule: Flexible schedules, including remote working options and flexible hours, to help employees balance their work and personal lives",
  "Wellness programs: Programs and resources to help employees maintain their physical and mental health, such as free gym membership, mental health support resources, and more",
  "Additional benefits: Matrix Rental Solutions offers a transportation or parking allowance, stock options, employee referral bonuses, and other perks",
];

export const aboutMatrix = `Matrix is a tenant analysis fintech platform that is changing the landscape of the growing, $500 Billion rental housing market. Traditional credit scoring is statistically less inclusive for minorities, immigrants and younger people, lacking a long credit history- over ⅔ of all US renters. Matrix uses real time data and produces an “ability to pay” score. Using a variety of factors, including Assets, Income and Previous Rental History, the result is more inclusive scoring that gives property managers insights into the full financial profile of a prospective tenant. Matrix streamlines the acceptance process and shortens it by up to 50%, helping more owners make their properties available to affordable housing and get leased up faster.`;
