import React from "react";
import cx from "classnames";
import Arrow from "../images/arrow.svg";

interface Props {
  label?: string;
  showSubmitButton?: boolean;
  isSubmitting?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
  name: string;
  required?: boolean;
  id: string;
  type?: string;
  placeholder?: string;
  error?: string | boolean;
  inputType?: "landing" | "normal";
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  boxShadow?: boolean;
  disabled?: boolean;
}

const FormText: React.FC<Props> = (props: Props) => {
  return (
    <div className={cx("w-full", props.className ?? "")}>
      <div className="flex justify-between">
        {props.label && (
          <label
            htmlFor={props.id}
            className={cx(
              "font-openSans font-semibold text-gs-20 text-regular-c6 mb-1",
              props.required && "flex items-start",
              props.labelClassName ?? ""
            )}
          >
            {props.label}
            {props.required && (
              <span className="ml-0.5 text-gs-20 font-semibold" aria-hidden>
                *
              </span>
            )}
          </label>
        )}

        {props.showSubmitButton && (
          <button type="submit" disabled={props.isSubmitting}>
            <img src={Arrow} alt="" className="rotate-45 h-7 w-7" />
          </button>
        )}
      </div>
      <input
        className={cx(props.inputClassName)}
        id={props.id}
        type={props.type ?? "text"}
        onChange={props.onChange}
        disabled={props.disabled}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
      />
      {!!props.error && (
        <span className="text-xs text-red-500">{props.error}</span>
      )}
    </div>
  );
};

export default React.memo(FormText);
