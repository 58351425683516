import { Link } from "gatsby";
import React from "react";
import { EXTERNAL_ROUTE_LINKS, ROUTE_LINKS } from "../utils/routes";
import cx from "classnames";
import MAnchor from "./MAnchor";

interface NavListProps {
  currentRoute?: string;
  className?: string;
  navItemClassName?: string;
  setShowRequestApiDocsModal: Function;
  setShowDtcApplyModal: Function;
}

interface NavItem {
  title: string;
  to?: string;
  type: "internal" | "external" | "modal";
}

const NavList: React.FC<NavListProps> = (props: NavListProps) => {
  const {
    currentRoute,
    navItemClassName,
    className,
    setShowRequestApiDocsModal,
    setShowDtcApplyModal,
  } = props;

  const navItems: NavItem[] = [
    {
      title: "Home",
      to: ROUTE_LINKS.index,
      type: "internal",
    },
    {
      title: "Property Managers",
      to: ROUTE_LINKS.managerLanding,
      type: "internal",
    },
    {
      title: "Renter",
      to: ROUTE_LINKS.renterLanding,
      type: "internal",
    },
    {
      title: "About Us",
      to: ROUTE_LINKS.aboutUs,
      type: "internal",
    },
    {
      title: "Careers",
      to: ROUTE_LINKS.careers,
      type: "internal",
    },
    {
      title: "What's in News",
      to: ROUTE_LINKS.news,
      type: "internal",
    },
    {
      title: "Contact Us",
      to: ROUTE_LINKS.contactUs,
      type: "internal",
    },
    {
      title: "Request API Docs",
      type: "modal",
    },
    {
      title: "Matrix Connect",
      to: EXTERNAL_ROUTE_LINKS.Login,
      type: "external",
    },
    {
      title: "Login",
      to: EXTERNAL_ROUTE_LINKS.Login,
      type: "external",
    },
  ];

  const uiHandler = (item: NavItem, index: number) => {
    if (item.type === "modal") {
      return (
        <li
          className={cx(
            `hover:text-primary cursor-pointer ${navItemClassName}`
          )}
          key={index}
          onClick={() =>
            item.title === "Matrix Connect"
              ? setShowDtcApplyModal(true)
              : setShowRequestApiDocsModal(true)
          }
        >
          {item.title}
        </li>
      );
    }

    if (item.type === "external") {
      return (
        <li
          className={cx(
            `hover:text-primary cursor-pointer ${navItemClassName}`
          )}
          key={index}
        >
          <MAnchor url={item.to!} ariaLabel={item.title}>
            {item.title}
          </MAnchor>
        </li>
      );
    }

    return (
      <li className={navItemClassName} key={index}>
        <Link
          to={item.to!}
          className={cx(
            "hover:text-primary",
            currentRoute === item.to && "text-primary"
          )}
        >
          {item.title}
        </Link>
      </li>
    );
  };

  return (
    <ol className={className}>
      {navItems.map((item, index) => uiHandler(item, index))}
    </ol>
  );
};

export default NavList;
