import React, { useRef } from "react";
import {
  ControlledMenu,
  Menu,
  MenuButton,
  useMenuState,
} from "@szhsin/react-menu";
import cx from "classnames";
import { navigate } from "gatsby";

interface Props {
  menuName: string;
  children: React.ReactNode;
  className?: string;
  toLink?: string;
}

const MenuDropdown: React.FC<Props> = (props: Props) => {
  const { menuName, children, className, toLink } = props;

  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });

  return (
    <>
      <div
        ref={ref}
        onPointerEnter={() => toggleMenu(true)}
        className={cx(
          className,
          menuProps && "hover:text-primary cursor-pointer rounded"
        )}
        onClick={() => {
          if (toLink) {
            navigate(toLink);
          }
        }}
      >
        {menuName}
      </div>

      <ControlledMenu
        {...menuProps}
        anchorRef={ref}
        onPointerLeave={() => toggleMenu(false)}
        onClose={() => toggleMenu(false)}
      >
        {children}
      </ControlledMenu>
    </>
  );
};

export default React.memo(MenuDropdown);
