import React from "react";
import { MenuItem } from "@szhsin/react-menu";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  showBottomBorder?: boolean;
  curveTopBorder?: boolean;
}

const CustomMenuItem: React.FC<Props> = (props: Props) => {
  const { children, showBottomBorder, curveTopBorder } = props;

  return (
    <MenuItem
      className={cx(
        "outline-none p-3 border-x border-t border-solid border-primary text-base cursor-pointer hover:text-primary bg-white last:rounded-b z-50 shadow-lg",
        showBottomBorder && "border-b",
        curveTopBorder && 'rounded-t',
      )}
    >
      {children}
    </MenuItem>
  );
};

CustomMenuItem.defaultProps = {
  showBottomBorder: false,
  curveTopBorder: false,
};

export default React.memo(CustomMenuItem);
