import React, { useContext } from "react";
import { Helmet } from "react-helmet/es/Helmet";
import { ENV_GATSBY_GOOGLE_ANALYTICS_KEY } from "../../utils/secrets";
import { DoNotTrackContext } from "../../contexts/do-not-track.context";
import { isNullOrEmptyString } from "../../utils/utils";

const GtagScript = () => {
  const analyticsKey = ENV_GATSBY_GOOGLE_ANALYTICS_KEY;
  const doNotTrack = useContext(DoNotTrackContext).doNotTrack;

  const gtagScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${analyticsKey}');
  `;

  return (
    <>
      {!isNullOrEmptyString(analyticsKey) && !doNotTrack && (
        <Helmet>
          <script
            src={`https://www.googletagmanager.com/gtag/js?id=${analyticsKey}`}
            type="text/javascript"
          />
          <script>{gtagScript}</script>
        </Helmet>
      )}
    </>
  );
};

export default GtagScript;
